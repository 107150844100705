import detectEthereumProvider from "@metamask/detect-provider";
import { useContext, useEffect, useState } from "react";
import { FantomPunksMarketContext } from "../hardhat/SymfoniContext";

export function useFTM() {
  const FTMCHAINID = "0xfa";
  const funker = useContext(FantomPunksMarketContext);

  const [isNetwork, setIsNetwork] = useState(false);
  const [address, setAddress] = useState(null);
  const [isMetamask, setIsMetamask] = useState(false);

  const FTM_MAINNET_PARAMS = {
    chainId: "0xfa", // A 0x-prefixed hexadecimal chainId
    chainName: "Fantom Opera",
    nativeCurrency: {
      name: "Fantom",
      symbol: "FTM",
    },
    rpcUrls: ["https://rpcapi.fantom.network"],
    blockExplorerUrls: ["https://ftmscan.com/"],
  };

  async function addFTMNetwork() {
    const provider = await detectEthereumProvider();
    if (!provider) return;
    await provider.request({
      method: "wallet_addEthereumChain",
      params: [FTM_MAINNET_PARAMS],
    });
  }

  useEffect(() => {
    const fetcher = async () => {
      const provider = await detectEthereumProvider();
      if (!provider) return;
      setIsNetwork(provider.chainId === FTMCHAINID);
      setAddress(provider.selectedAddress);
      setIsMetamask(provider.isMetaMask);
    };

    fetcher();
  }, [funker.instance]);

  return {
    isConnected: funker.instance && address && isNetwork,
    isNetwork,
    funker: funker,
    isMetamask,
    addFTMNetwork,
  };
}
