import Header from "./Header";
import Twitter from "../assets/twitter";

function BaseLayout({ children }) {
  return (
    <div className="flex min-h-full w-full">
      <Header />
      <main
        style={{ minHeight: "calc(100% - 64px)" }}
        className="mt-16  w-full"
      >
        {children}
      </main>
      <a
        target="_blank"
        href="https://twitter.com/FTMPunks"
        className="fixed bg-white shadow-xl rounded-full text-blue-600 bottom-14 p-2.5 right-10 w-12"
        rel="noreferrer"
      >
        <Twitter />
      </a>
      <div className="fixed bg-white shadow-xl text-center text-blue-600 bottom-0 p-2.5 left-0 right-0">
        This project is not affiliated to Larva Labs LLC
      </div>
    </div>
  );
}

export default BaseLayout;
