import Modal from "./Modal";

function FunkCard({ id, index }) {
  return (
    <div
      style={{
        width: "7rem",
        marginRight: (index + 1) % 3 !== 0 ? "1.5rem" : "0px",
      }}
      className="mt-16 px-3 h-44 pt-8 bg-white rounded flex pb-9 shadow-sm hover:shadow-2xl flex-col items-center justify-center"
    >
      <span className="text-center font-semibold">Punk</span>
      <span className="text-lg font-bold">#{id}</span>
      <div className="bg-blue-600 rounded">
        <img
          alt={`punk-${id}`}
          src={`https://fantompunks.s3.ca-central-1.amazonaws.com/punks/punk${id}.png`}
          className="w-28"
          style={{ imageRendering: "pixelated" }}
        />
      </div>
      <Modal id={id} />
    </div>
  );
}

export default FunkCard;
