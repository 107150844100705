import TransLog from "../assets/logoTrans";
import { useFTM } from "../hooks/FTMHook";

import { useHistory } from "react-router-dom";

function Header() {
  const history = useHistory();
  const { isNetwork, addFTMNetwork, isConnected, isMetamask } = useFTM();

  async function launchMetamask() {
    if (window.ethereum) {
      try {
        await window.ethereum.request({
          method: "eth_requestAccounts",
        });
      } catch (error) {
        if (error.code === 4001) {
          // User rejected request
        }
      }
    }
  }

  function getButton() {
    if (!isMetamask) return "You need to install Metamask";
    if (isConnected && isNetwork) return "Connected";
    else if (isNetwork && !isConnected) return "Connect Wallet";
    else return "Switch to FTM Network";
  }

  return (
    <header
      className={`fixed shadow-lg top-0 right-0 left-0 z-50 bg-blue-600 text-white`}
    >
      <nav className="max-w-screen-md font-semibold h-16 flex items-center container w-full justify-between mx-auto">
        <div className="h-full items-center flex">
          <TransLog />
          <span>Beta</span>
        </div>
        <span
          onClick={() => history.push("/")}
          className="hover:text-blue-300 cursor-pointer"
        >
          Mint
        </span>
        <span
          onClick={() => history.push("/punks")}
          className="hover:text-blue-300 cursor-pointer"
        >
          View my Punks
        </span>
        <span
          onClick={() => history.push("/market")}
          className="hover:text-blue-300 cursor-pointer"
        >
          Market Place
        </span>
        <button
          onClick={() => (isNetwork ? launchMetamask() : addFTMNetwork())}
          className="rounded-full px-5 py-2 font-bold bg-white bg-opacity-20 hover:bg-opacity-40"
        >
          {getButton()}
        </button>
      </nav>
    </header>
  );
}

export default Header;
