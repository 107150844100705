import { useEffect, useState } from "react";
import { useFTM } from "../hooks/FTMHook";
import { ethers } from "ethers";
import Logo from "../assets/logo";
import confetti from "canvas-confetti";

function Mint() {
  const { funker, isNetwork, isConnected, isMetamask } = useFTM();
  const [count, setCount] = useState(10000);

  function fire(particleRatio, opts) {
    var count = 200;
    var defaults = {
      origin: { y: 0.7 },
    };
    confetti(
      Object.assign({}, defaults, opts, {
        particleCount: Math.floor(count * particleRatio),
      })
    );
  }

  //   const frame = () => {
  //     var end = Date.now() + (15 * 1000);

  //     confetti({
  //       particleCount: 4,
  //       angle: 60,
  //       spread: 65,
  //       origin: { x: 0 },
  //       colors: ["#004ddb", "#FFF"]
  //     });
  //     confetti({
  //       particleCount: 3,
  //       angle: 120,
  //       spread: 65,
  //       origin: { x: 1},
  //       colors: ["#004ddb", "#FFF"]
  //     });

  //     if (Date.now() < end) {
  //       requestAnimationFrame(frame);
  //     }
  //   };

  useEffect(() => {
    if (window.ethereum) {
      window.ethereum.removeAllListeners();
      window.ethereum.on("chainChanged", () => {
        document.location.reload();
      });
    }

    const fetcher = async () => {
      if (
        (isNetwork && funker.instance) ||
        isConnected ||
        (!isMetamask && funker.instance)
      ) {
        const punksRemaining = await funker.instance.getRemaining();
        setCount(punksRemaining);
        funker.instance.removeAllListeners("Assign");
        funker.instance.on("Assign", async () => {
          const punksRemaining = await funker.instance.getRemaining();
          setCount(punksRemaining);
          confettiFire();
        });
      }
    };
    fetcher();
    function confettiFire() {
      fire(0.25, {
        spread: 26,
        startVelocity: 55,
      });
      fire(0.2, {
        spread: 60,
      });
      fire(0.35, {
        spread: 100,
        decay: 0.91,
        scalar: 0.8,
      });
      fire(0.1, {
        spread: 120,
        startVelocity: 25,
        decay: 0.92,
        scalar: 1.2,
      });
      fire(0.1, {
        spread: 120,
        startVelocity: 45,
      });
    }
  }, [funker.instance, isConnected, isNetwork, isMetamask]);

  function handleMint() {
    if (funker.instance) {
      funker.instance.getPunk({ value: ethers.utils.parseEther("100") });
    }
  }

  return (
    <div className="w-full h-full bg-gradient-to-r from-blue-300 to-blue-600 items-center justify-center pb-24 flex">
      <div className="mt-16 h-96 bg-white rounded flex pb-9 shadow-md flex-col items-center justify-center">
        <div className="w-72 rounded">
          <Logo />
        </div>
        <button
          onClick={() => handleMint()}
          className="w-44 rounded-full mt-7 border border-blue-600 px-5 py-2 text-blue-600 font-semibold bg-white hover:bg-blue-500 hover:text-white"
        >
          Mint
        </button>
        <span className="font-semibold mt-2">
          {count.toString()} Punks Remaining!
        </span>
      </div>
    </div>
  );
}

export default Mint;
