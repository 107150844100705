function TransLogo() {
  return (
    <svg
      className="h-full cursor-pointer p-2"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 234 342"
    >
      <defs></defs>
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <rect fill="#fff" x="54" y="18" width="18" height="18" />
          <rect fill="#fff" x="36" y="36" width="18" height="18" />
          <rect fill="#fff" x="36" y="54" width="18" height="18" />
          <rect fill="#fff" x="36" y="72" width="18" height="18" />
          <rect fill="#fff" x="36" y="90" width="18" height="18" />
          <rect fill="#fff" x="36" y="108" width="18" height="18" />
          <rect fill="#fff" x="18" y="108" width="18" height="18" />
          <rect fill="#fff" y="126" width="18" height="18" />
          <rect fill="#fff" x="18" y="144" width="18" height="18" />
          <rect fill="#fff" x="18" y="162" width="18" height="18" />
          <rect fill="#fff" x="36" y="162" width="18" height="18" />
          <rect fill="#fff" x="36" y="180" width="18" height="18" />
          <rect fill="#fff" x="36" y="198" width="18" height="18" />
          <rect fill="#fff" x="36" y="216" width="18" height="18" />
          <rect fill="#fff" x="36" y="234" width="18" height="18" />
          <rect fill="#fff" x="36" y="252" width="18" height="18" />
          <rect fill="#fff" x="36" y="270" width="18" height="18" />
          <rect fill="#fff" x="36" y="288" width="18" height="18" />
          <rect fill="#fff" x="36" y="306" width="18" height="18" />
          <rect fill="#fff" x="36" y="324" width="18" height="18" />
          <rect fill="#fff" x="216" y="108" width="18" height="18" />
          <rect fill="#fff" x="216" y="126" width="18" height="18" />
          <rect fill="#fff" x="216" y="144" width="18" height="18" />
          <rect fill="#fff" x="216" y="162" width="18" height="18" />
          <rect fill="#fff" x="216" y="180" width="18" height="18" />
          <rect fill="#fff" x="216" y="198" width="18" height="18" />
          <rect fill="#fff" x="216" y="216" width="18" height="18" />
          <rect fill="#fff" x="216" y="234" width="18" height="18" />
          <rect fill="#fff" x="216" y="252" width="18" height="18" />
          <rect fill="#fff" x="216" y="54" width="18" height="18" />
          <rect fill="#fff" x="216" y="72" width="18" height="18" />
          <rect fill="#fff" x="216" y="90" width="18" height="18" />
          <rect fill="#fff" x="216" y="36" width="18" height="18" />
          <rect fill="#fff" x="144" y="162" width="18" height="18" />
          <rect fill="#fff" x="144" y="180" width="18" height="18" />
          <rect fill="#fff" x="144" y="198" width="18" height="18" />
          <rect fill="#fff" x="198" y="108" width="18" height="18" />
          <rect fill="#fff" x="180" y="126" width="18" height="18" />
          <rect fill="#fff" x="90" y="126" width="18" height="18" />
          <rect fill="#fff" x="108" y="108" width="18" height="18" />
          <rect fill="#fff" x="198" y="18" width="18" height="18" />
          <rect fill="#fff" x="198" y="270" width="18" height="18" />
          <rect fill="#fff" x="108" y="288" width="18" height="18" />
          <rect fill="#fff" x="108" y="306" width="18" height="18" />
          <rect fill="#fff" x="108" y="324" width="18" height="18" />
          <rect
            fill="#fff"
            x="180"
            y="288"
            width="18"
            height="18"
            transform="translate(486 108) rotate(90)"
          />
          <rect
            fill="#fff"
            x="162"
            y="288"
            width="18"
            height="18"
            transform="translate(468 126) rotate(90)"
          />
          <rect
            fill="#fff"
            x="144"
            y="288"
            width="18"
            height="18"
            transform="translate(450 144) rotate(90)"
          />
          <rect
            fill="#fff"
            x="126"
            y="288"
            width="18"
            height="18"
            transform="translate(432 162) rotate(90)"
          />
          <rect
            fill="#fff"
            x="180"
            y="234"
            width="18"
            height="18"
            transform="translate(432 54) rotate(90)"
          />
          <rect
            fill="#fff"
            x="162"
            y="234"
            width="18"
            height="18"
            transform="translate(414 72) rotate(90)"
          />
          <rect
            fill="#fff"
            x="144"
            y="234"
            width="18"
            height="18"
            transform="translate(396 90) rotate(90)"
          />
          <rect
            fill="#fff"
            x="126"
            y="234"
            width="18"
            height="18"
            transform="translate(378 108) rotate(90)"
          />
          <rect fill="#fff" x="108" width="18" height="18" />
          <rect
            fill="#fff"
            x="180"
            width="18"
            height="18"
            transform="translate(198 -180) rotate(90)"
          />
          <rect
            fill="#fff"
            x="162"
            width="18"
            height="18"
            transform="translate(180 -162) rotate(90)"
          />
          <rect
            fill="#fff"
            x="144"
            width="18"
            height="18"
            transform="translate(162 -144) rotate(90)"
          />
          <rect
            fill="#fff"
            x="126"
            width="18"
            height="18"
            transform="translate(144 -126) rotate(90)"
          />
          <rect fill="#fff" x="72" width="18" height="18" />
          <rect
            fill="#fff"
            x="90"
            width="18"
            height="18"
            transform="translate(108 -90) rotate(90)"
          />
          <rect
            fill="#fff"
            x="108"
            y="234"
            width="18"
            height="18"
            transform="translate(360 126) rotate(90)"
          />
          <rect fill="#95baff" x="90" y="108" width="18" height="18" />
          <rect fill="#95baff" x="108" y="126" width="18" height="18" />
          <rect fill="#95baff" x="180" y="108" width="18" height="18" />
          <rect fill="#95baff" x="198" y="126" width="18" height="18" />
        </g>
      </g>
    </svg>
  );
}

export default TransLogo;
