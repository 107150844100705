import React, { useState, useEffect } from "react";
import { useFTM } from "../hooks/FTMHook";

export default function Modal({ id }) {
  const [showModal, setShowModal] = React.useState(false);
  const [address, setAddress] = useState("");
  const { funker } = useFTM();

  async function send() {
    if (funker.instance) {
      await funker.instance.transferPunk(address, id);
    }
  }

  useEffect(() => {
    const fetcher = async () => {
      if (funker.instance) {
        funker.instance.removeAllListeners("PunkTransfer");
        funker.instance.on("PunkTransfer", async (from, to, _id) => {
          if (id.toString() === _id.toString()) setShowModal(false);
        });
      }
    };
    fetcher();
  });

  return (
    <>
      <span
        onClick={() => setShowModal(true)}
        style={{ lineHeight: 0 }}
        className="h-3 w-full cursor-pointer text-white text-center mt-1.5 rounded self-end px-2 py-3 bg-blue-600"
      >
        Send
      </span>
      {showModal ? (
        <>
          <div
            style={{
              top: "50%",
              left: "50%",
              width: 280,
              height: 190,
              marginTop: -120,
              marginLeft: -130,
              position: "fixed",
            }}
            className="items-center flex overflow-x-hidden overflow-y-auto z-50 outline-none focus:outline-none"
          >
            {/*content*/}
            <div className="h-full px-4 py-4 border-0 rounded-lg justify-between shadow-lg flex flex-col w-full bg-white outline-none focus:outline-none">
              {/*header*/}
              <div className="mb-2 flex flex-col justify-between">
                <span className="text-xl mb-4 font-semibold">
                  Transfer Punk #{id.toString()}
                </span>
                {/*body*/}
                <input
                  onChange={(e) => setAddress(e.target.value)}
                  className="pl-1 mb-5 w-full rounded border border-blue-200"
                  placeholder="Enter recipients address"
                />
                <button
                  onClick={() => send()}
                  className="w-full text-blue-600 font-semibold text-sm border-blue-600 hover:text-white hover:bg-blue-600 border px-1 py-1 rounded"
                >
                  Send
                </button>
              </div>
              <button
                className="rounded border-red-500 hover:bg-red-500 hover:text-white w-full text-red-500 border background-transparent font-bold px-6 py-1 text-sm outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                type="button"
                onClick={() => setShowModal(false)}
              >
                Close
              </button>
            </div>
          </div>
          <div
            onClick={() => setShowModal(false)}
            className="opacity-50 fixed inset-0 z-40 bg-blue-800"
          ></div>
        </>
      ) : null}
    </>
  );
}
