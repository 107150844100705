import React from "react";
import Mint from "./pages/Mint";
import Funks from "./pages/Funks";
import Market from "./pages/Market";
import "./App.css";
import BaseLayout from "./layout/BaseLayout";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { Symfoni } from "./hardhat/SymfoniContext";

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/punks">
          <Symfoni autoInit={true} showLoading={false}>
            <BaseLayout>
              <Funks />
            </BaseLayout>
          </Symfoni>
        </Route>

        <Route exact path="/market">
          <BaseLayout>
            <Market />
          </BaseLayout>
        </Route>

        <Route exact path="*">
          <Symfoni autoInit={true} showLoading={false}>
            <BaseLayout>
              <Mint />
            </BaseLayout>
          </Symfoni>
        </Route>
      </Switch>
    </BrowserRouter>
  );
}

export default App;
