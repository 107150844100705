export const fetchData = async (address) => {
  try {
    const res = await fetch(
      `https://oj9qx31486.execute-api.us-east-1.amazonaws.com/default/getMyPunks?to=${address}`,
      {
        method: "GET",
      }
    );
    console.log("got punks");
    return await res.json();
  } catch (err) {
    console.log(err);
  }
};
