function Logo() {
  return (
    <svg
      className="w-full rounded h-full"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 401 401"
    >
      <defs></defs>
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <rect fill="#1969ff" width="401" height="401" />
          <rect fill="#fff" x="127" y="76" width="18" height="18" />
          <rect fill="#fff" x="109" y="94" width="18" height="18" />
          <rect fill="#fff" x="109" y="112" width="18" height="18" />
          <rect fill="#fff" x="109" y="130" width="18" height="18" />
          <rect fill="#fff" x="109" y="148" width="18" height="18" />
          <rect fill="#fff" x="109" y="166" width="18" height="18" />
          <rect fill="#fff" x="91" y="166" width="18" height="18" />
          <rect fill="#fff" x="73" y="184" width="18" height="18" />
          <rect fill="#fff" x="91" y="202" width="18" height="18" />
          <rect fill="#fff" x="91" y="220" width="18" height="18" />
          <rect fill="#fff" x="109" y="220" width="18" height="18" />
          <rect fill="#fff" x="109" y="238" width="18" height="18" />
          <rect fill="#fff" x="109" y="256" width="18" height="18" />
          <rect fill="#fff" x="109" y="274" width="18" height="18" />
          <rect fill="#fff" x="109" y="292" width="18" height="18" />
          <rect fill="#fff" x="109" y="310" width="18" height="18" />
          <rect fill="#fff" x="109" y="328" width="18" height="18" />
          <rect fill="#fff" x="109" y="346" width="18" height="18" />
          <rect fill="#fff" x="109" y="364" width="18" height="18" />
          <rect fill="#fff" x="109" y="382" width="18" height="18" />
          <rect fill="#fff" x="289" y="166" width="18" height="18" />
          <rect fill="#fff" x="289" y="184" width="18" height="18" />
          <rect fill="#fff" x="289" y="202" width="18" height="18" />
          <rect fill="#fff" x="289" y="220" width="18" height="18" />
          <rect fill="#fff" x="289" y="238" width="18" height="18" />
          <rect fill="#fff" x="289" y="256" width="18" height="18" />
          <rect fill="#fff" x="289" y="274" width="18" height="18" />
          <rect fill="#fff" x="289" y="292" width="18" height="18" />
          <rect fill="#fff" x="289" y="310" width="18" height="18" />
          <rect fill="#fff" x="289" y="112" width="18" height="18" />
          <rect fill="#fff" x="289" y="130" width="18" height="18" />
          <rect fill="#fff" x="289" y="148" width="18" height="18" />
          <rect fill="#fff" x="289" y="94" width="18" height="18" />
          <rect fill="#fff" x="217" y="220" width="18" height="18" />
          <rect fill="#fff" x="217" y="238" width="18" height="18" />
          <rect fill="#fff" x="217" y="256" width="18" height="18" />
          <rect fill="#fff" x="271" y="166" width="18" height="18" />
          <rect fill="#fff" x="253" y="184" width="18" height="18" />
          <rect fill="#fff" x="163" y="184" width="18" height="18" />
          <rect fill="#fff" x="181" y="166" width="18" height="18" />
          <rect fill="#fff" x="271" y="76" width="18" height="18" />
          <rect fill="#fff" x="271" y="328" width="18" height="18" />
          <rect fill="#fff" x="181" y="346" width="18" height="18" />
          <rect fill="#fff" x="181" y="364" width="18" height="18" />
          <rect fill="#fff" x="181" y="382" width="18" height="18" />
          <rect
            fill="#fff"
            x="253"
            y="346"
            width="18"
            height="18"
            transform="translate(617 93) rotate(90)"
          />
          <rect
            fill="#fff"
            x="235"
            y="346"
            width="18"
            height="18"
            transform="translate(599 111) rotate(90)"
          />
          <rect
            fill="#fff"
            x="217"
            y="346"
            width="18"
            height="18"
            transform="translate(581 129) rotate(90)"
          />
          <rect
            fill="#fff"
            x="199"
            y="346"
            width="18"
            height="18"
            transform="translate(563 147) rotate(90)"
          />
          <rect
            fill="#fff"
            x="253"
            y="292"
            width="18"
            height="18"
            transform="translate(563 39) rotate(90)"
          />
          <rect
            fill="#fff"
            x="235"
            y="292"
            width="18"
            height="18"
            transform="translate(545 57) rotate(90)"
          />
          <rect
            fill="#fff"
            x="217"
            y="292"
            width="18"
            height="18"
            transform="translate(527 75) rotate(90)"
          />
          <rect
            fill="#fff"
            x="199"
            y="292"
            width="18"
            height="18"
            transform="translate(509 93) rotate(90)"
          />
          <rect fill="#fff" x="181" y="58" width="18" height="18" />
          <rect
            fill="#fff"
            x="253"
            y="58"
            width="18"
            height="18"
            transform="translate(329 -195) rotate(90)"
          />
          <rect
            fill="#fff"
            x="235"
            y="58"
            width="18"
            height="18"
            transform="translate(311 -177) rotate(90)"
          />
          <rect
            fill="#fff"
            x="217"
            y="58"
            width="18"
            height="18"
            transform="translate(293 -159) rotate(90)"
          />
          <rect
            fill="#fff"
            x="199"
            y="58"
            width="18"
            height="18"
            transform="translate(275 -141) rotate(90)"
          />
          <rect fill="#fff" x="145" y="58" width="18" height="18" />
          <rect
            fill="#fff"
            x="163"
            y="58"
            width="18"
            height="18"
            transform="translate(239 -105) rotate(90)"
          />
          <rect
            fill="#fff"
            x="181"
            y="292"
            width="18"
            height="18"
            transform="translate(491 111) rotate(90)"
          />
          <rect fill="#95baff" x="163" y="166" width="18" height="18" />
          <rect fill="#95baff" x="181" y="184" width="18" height="18" />
          <rect fill="#95baff" x="253" y="166" width="18" height="18" />
          <rect fill="#95baff" x="271" y="184" width="18" height="18" />
        </g>
      </g>
    </svg>
  );
}

export default Logo;
