function Market() {
  return (
    <div className="flex w-full h-full bg-gradient-to-r from-blue-300 to-blue-600 justify-center items-center flex-col">
      <div className="mt-16 bg-white w-96 rounded flex py-6 px-4 shadow-md flex-col items-center justify-center">
        <span className="text-lg font-semibold">
          Marketplace releasing after all punks have been minted!
        </span>
      </div>
    </div>
  );
}

export default Market;
