import { useState } from "react";
import { fetchData } from "../lambda/lambda";
import FunkCard from "../components/FunkCard";

function Funk() {
  const [address, setAddress] = useState("");
  const [punks, setPunks] = useState([]);


  function sort(_punks) {
    const p = _punks
    p.sort(function(a, b) {
        return a._id - b._id;
      });
      setPunks(p)
  }
  
  return (
    <div className="flex w-full h-full bg-gradient-to-r from-blue-300  to-blue-600 pb-16 flex-col">
      <div className="flex flex-col justify-center items-center">
        <div className="mt-16 bg-white w-96 rounded flex py-6 px-4 shadow-md flex-col items-center justify-center">
          <span className="font-bold text-2xl mb-4">Punks Finder!</span>
          <label className="font-semibold">
            Enter your wallet address to find your Punks
          </label>
          <input
            className="border w-72 rounded mt-1 mb-2"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
          />

          <div className="bg-blue-600 mt-7 mb-7 rounded-full w-44">
            <button
              onClick={() => fetchData(address).then((p) => sort(p))}
              className="w-44 rounded-full px-5 py-2 text-white font-semibold bg-white bg-opacity-10 hover:bg-opacity-20"
            >
              Search
            </button>
          </div>
        </div>
        <div className="w-96 flex flex-wrap">
          {punks.map((_p, i) => (
            <FunkCard key={i} index={i} id={_p._id} />
          ))}
        </div>
      </div>
    </div>
  );
}

export default Funk;
